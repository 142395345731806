import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Grid, Box, TextField, Button, Typography, useMediaQuery } from '@mui/material';
import image1 from '../asserts/Banner1.jpeg';
import image2 from '../asserts/Banner2.jpeg';
import image3 from '../asserts/Banner3.jpeg';
import { CheckCircle } from '@mui/icons-material';

const Banner = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const isMobile = useMediaQuery("(min-width:360px) and (max-width:500px)");

  function CustomPrevArrow(props) {
    return (
      <div
        {...props}
        style={{ ...props.style, left: '10px', zIndex: 1, fontSize: '24px', cursor: 'pointer' }}
      >
        &#9665;
      </div>
    );
  }

  function CustomNextArrow(props) {
    return (
      <div
        {...props}
        style={{ ...props.style, right: '10px', zIndex: 1, fontSize: '24px', cursor: 'pointer' }}
      >
        &#9655;
      </div>
    );
  }

  return (
    <Box sx={{ position: 'relative', width: '100%', marginTop: '-25px' }}>
      <Slider {...settings}>
        <Grid>
          <img
            src={image2}
            alt="img"
            style={{ width: '100%', height: isMobile ? '250px' : '75vh' }}
          />
        </Grid>
        <Grid>
          <img
            src={image3}
            alt="img"
            style={{ width: '100%', height: isMobile ? '250px' : '75vh' }}
          />
        </Grid>
        <Grid>
          <img
            src={image1}
            alt="img"
            style={{ width: '100%', height: isMobile ? '250px' : '75vh' }}
          />
        </Grid>
      </Slider>

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          right: 0,
          display: 'flex',
          zIndex: 3,
          transform: 'translateY(-50%)',
          justifyContent: 'space-between',
          padding: '0 20px',
        }}
      >
        {/* Left Content Section */}
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            padding: { xs: '10px', md: '40px' },
            color: 'white',
            textAlign: 'left',
            zIndex: 4,
            mt:{md:8,xs:0},
          }}
        >
          <Typography
            sx={{
              textAlign:'center',
              fontWeight: 'bold',
              marginBottom: 2,
              color: '#fff',
              fontSize:{md:"50px",xs:"25px"},
              mt:{md:0,xs:3},
            }}
          >
            Medical Colleges
          </Typography>
          <Typography
            variant="body1"
            sx={{
              marginBottom: 3,
            textAlign:'center',
              color: '#f2f2f2',
              fontSize: {md :"20px",xs:"20px"}
            }}
          >
            Get access to top medical colleges with up to 20 lakhs in scholarships. Our experts guide you through every step of the application process. Start your medical career today!
          </Typography>
        </Box>

        {/* Right Side Form Section (Apply Form) */}
        {!isMobile && (
          <Box
            sx={{
              width: { xs: '50%', sm: '70%', md: '20%', lg: '30%' },
              position: 'relative',
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              color: 'black',
              zIndex: 4,
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              sx={{ marginBottom: 1, textAlign: 'center', fontWeight: 'bold' }}
            >
              Apply for Medical Colleges
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginBottom: 2,
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '15px',
              }}
            >
              Up to 20 lakhs scholarship for merit students in selected colleges.
            </Typography>
            <TextField
              fullWidth
              placeholder="Name"
              variant="outlined"
              name="name"
              sx={{ marginBottom: '15px' }}
            />
            <TextField
              fullWidth
              placeholder="Phone Number"
              variant="outlined"
              name="phone"
              sx={{ marginBottom: '10px' }}
            />
            <TextField
              fullWidth
              placeholder="Message"
              variant="outlined"
              name="message"
              multiline
              rows={4}
              sx={{ marginBottom: '10px' }}
            />
            <Button
              variant="contained"
              startIcon={<CheckCircle />}
              fullWidth
              sx={{
                backgroundColor: '#005e69',
                '&:hover': {
                  backgroundColor: '#004b54',
                },
              }}
            >
              Apply Now
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Banner;